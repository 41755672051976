import {Timeline, TimelineEvent} from "react-event-timeline"
import moment from 'moment'
import { eventCategories } from '../../../common/variables'
import Phosphor from "https://framer.com/m/Phosphor-9Q9Y.js@qyebYqHqVZaLb5Up06qa"
import User from '../user'
import { useUser } from '../../utils/useUser'
import { EventDetailPopup } from '../modal'
import { Grid } from '@geist-ui/core'

const EventTitle = ({ event }) => {

    const { user } = useUser()

    const { title } = eventCategories[event.category] ?? { title: "Unknown Event" }

    return <Grid.Container>
        {(title + (event.description ? ": " + event.description : ""))} {(event.user !== user?._id) ? <User initialsOnly userId={event.user} /> : null}
    </Grid.Container>
}

const PublicEventTitle = ({ event }) => {
    return <Grid.Container>
        {
            (eventCategories[event.category].title +
                (event.description ? ": " + event.description : ""))}
        {/* <User initialsOnly userId={event.user} /> */}
    </Grid.Container>
}


const EventTimeline = ({ events, setModal, onClick, isPublic }) => {

    const closeModal = () => setModal()

    if (!events) return ""

    return <Timeline lineColor='#F1F1F1'>
        {events?.map((event, i) => {

            const { icon } = eventCategories[event?.category] ?? { icon: "Question" }

            return <TimelineEvent
                onClick={() => {
                    if (onClick) onClick(event)
                    else setModal(<EventDetailPopup showPlant close={closeModal} event={event} />)
                }}
                container='none'
                collapsible
                style={{
                    fontSize: "17px",
                    fontWeight: 400
                }}
                icon={<Phosphor style={{ width: "20px" }} color='black' name={icon} />}
                key={i}
                bubbleStyle={{ backgroundRepeat: 'no-repeat', border: '1px solid black', backgroundColor: "white", }} title={
                    isPublic ? <PublicEventTitle event={event} /> : <EventTitle event={event} />
                }

                subtitle={<>
                    <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>{moment(event.date).fromNow()}</span>
                </>}
            >
            </TimelineEvent>
        })}
    </Timeline>
}

export default EventTimeline