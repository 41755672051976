import { Card, CardContent, CardHeader, CardTitle } from "@/client/ui/card"
import { Spacer, Grid } from "@geist-ui/core"
import Skeleton from "react-loading-skeleton"

const LoadingView = () => {
    return <div style={{ background: "white", width: "100%", height: '160px', borderRadius: "10px", padding: "20px" }}>
        <Skeleton style={{ width: "15%" }} />
        <Skeleton style={{ width: "50%" }} />
        <Spacer />
        <Skeleton style={{ height: "50px" }} />
    </div>
}

const StatisticCard = (props) => {
    if (props.loading) return <Grid sm={8} xs={24} direction='column'><LoadingView /></Grid>
    return <Grid sm={8} xs={24}>
        <Card style={{width: "100%", padding: "0px"}}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0">
                <CardTitle className="text-sm font-medium">
                    {props.title}
                </CardTitle>
                {props?.Icon && <props.Icon style={{position: "relative", top: -8, right: -6}} size={24}></props.Icon>}
            </CardHeader>
            <CardContent>
                <div style={{fontSize: "48px", fontWeight: "bold"}} className="text-xl font-bold">{props?.amount}</div>
                <p className="text-xs text-muted-foreground">
                    Updated now
                </p>
            </CardContent>
        </Card>
        {/* <Stat style={{ width: "100%" }} {...props}></Stat> */}
    </Grid>
}

export default StatisticCard 