import HubItem from "https://framer.com/m/HubItem-vZVB.js@WzQE8HYLEVcjORo9rYHE"
import HubTitleFromFramer from "https://framer.com/m/HubTitle-OTfq.js@jqAxoHDmUJMcmSkHFoTK"
import PlantLoader from '../plant/loader'

import moment from 'moment'

const HubListItem = ({ hub, onClick }) => {
    const isOnline = Date.now() - hub.lastSeen < 7000 ? 'success' : 'error'
    return <HubItem
        status={isOnline}
        // Using default values:
        style={{width: "100%"}}
        actionText="View Details"
        name={hub?.name}
        subtitle={"Last seen: " + !!hub.lastSeen ? moment(hub.lastSeen).fromNow() : "Never"}
        tap={onClick}
    />
}

const HubTitle = ({hub}) =>{
    if (!hub) return <PlantLoader/>
    const isOnline = Date.now() - hub.lastSeen < 70000 ? 'success' : 'error'
    return <HubTitleFromFramer
        status={isOnline}
        style={{width: "100%"}}
        name={hub?.name}
        subtitle={"Last seen: " + !!hub.lastSeen ? moment(hub.lastSeen).fromNow() : "Never"}
    />
} 


export {HubListItem, HubTitle}